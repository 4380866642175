import { ProductUnit, Quantity } from "src/redux/apiTypes";
import useLocalization from 'src/services/localization/useLocalization';
import getAmountSuffix from "src/utils/product/getAmountSuffix";
import { useToggles } from "src/utils/toggles/useToggles";
import AddButton from "../../AddButton/AddButton";
import QuantityBox from "../../QuantityBox/QuantityBox";

export interface Props {
  amount: number;
  unit: ProductUnit;
  bundle: number;
  quantity: Quantity;
  itemInStock: boolean;
  place: string;

  onAddToCart(): void;
  onChangeAmount(amount: number): void;
}

const ProductRowAddButtons = (props: Props) => {
  const localize = useLocalization();
  const {
    amount,
    unit,
    bundle,
    quantity,
    itemInStock,
    place,
    onChangeAmount,
    onAddToCart,
  } = props;
  const { BUNDLES_AVAILABLE } = useToggles();
  const amountSuffix = getAmountSuffix(unit, bundle, localize, BUNDLES_AVAILABLE);

  const itemIsAdded = amount > 0;

  if (!itemInStock) {
    return (
      <div className='ProductUnavailable' data-marker='Product unavailable'>
        {localize('product_not_in_stock')}
        <style jsx>{`
          .ProductUnavailable {
            color: var(--colors-doveGray);
          }
        `}</style>
      </div>
    );
  }

  if (itemIsAdded) {
    return (
      <QuantityBox
        boxSmall
        low
        inputMargin='0 4px'
        amount={amount}
        place={place}
        onChangeAmount={onChangeAmount}
        unit={unit}
        quantity={quantity}
        suffix={amountSuffix}
      />
    );
  }

  return (
    <AddButton
      onClick={onAddToCart}
      withSmallHeight
    />
  );
};

export default ProductRowAddButtons;
